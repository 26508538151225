import PropTypes from 'prop-types';
import React from 'react';
import BrandLogo from './brand-logo';
import './header.scss';
import LocalizedLink from './localized-link';
import { Trans } from 'react-i18next';
import Sticky from 'react-sticky-el';
import Icon from './icon';
import AppDownloadButton from './app-download-button';
import LanguageSwitcher from './language-switcher';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileNavigationActive: false,
    };
  }

  toggle = () => {
    this.setState(state => ({ isMobileNavigationActive: !state.isMobileNavigationActive }));
  };

  render() {
    return (
      <nav>
        <Sticky
          className={`navbar st-header ${this.state.isMobileNavigationActive && 'is-active'}
            ${this.props.hasDarkBackground && 'has-dark-bg'}`}
          role="navigation"
          aria-label="main navigation"
        >
          <div className="navbar-brand st-header__navbar-brand">
            <LocalizedLink to="/" className="navbar-item st-header__navbar-item">
              <BrandLogo className="st-header__logo" />
            </LocalizedLink>

            <button
              className={`navbar-burger burger button is-dark ${this.state
                .isMobileNavigationActive && 'is-active'}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
              onClick={this.toggle}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </button>
          </div>

          <div
            className={`navbar-menu st-header__navbar-menu ${this.state.isMobileNavigationActive &&
              'is-active'}`}
          >
            <div className="navbar-start st-header__navbar-start">
              {this.props.menuLinks.map((item, index) => (
                <div className="navbar-item st-header__navbar-item" key={index}>
                  <LocalizedLink
                    to={item.link}
                    className="st-header__link"
                    activeClassName="is-active"
                    key={index}
                  >
                    <Trans i18nKey={item.name}></Trans>
                  </LocalizedLink>
                </div>
              ))}
            </div>

            <div className="navbar-end st-header__navbar-end">
              <div className="navbar-item st-header__navbar-item is-hidden-tablet">
                <div className="columns is-mobile">
                  <div className="column is-half">
                    <div className="title is-5">
                      <Trans i18nKey="Header:travelers"></Trans>
                    </div>
                    <div className="st-header__download-buttons">
                      <AppDownloadButton type="ios"></AppDownloadButton>
                      <AppDownloadButton type="android"></AppDownloadButton>
                    </div>
                  </div>
                  <div className="column is-half">
                    <div className="title is-5">
                      <Trans i18nKey="Header:business"></Trans>
                    </div>
                    <a
                      href="https://signup.stampit.co/"
                      className="st-header__dropdown-item has-icon"
                    >
                      <span>
                        <Trans i18nKey="Header:signUp"></Trans>
                      </span>
                      <Icon name="arrow-right" />
                    </a>
                    <a
                      href="https://merchants.stampit.co/"
                      className="st-header__dropdown-item has-icon"
                    >
                      <span>
                        <Trans i18nKey="Global:phrase.login"></Trans>
                      </span>
                      <Icon name="arrow-right" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="navbar-item st-header__navbar-item is-hidden-mobile">
                <div className="field is-grouped">
                  <div className="control">
                    <LanguageSwitcher />
                  </div>
                  <div className="control is-expanded">
                    <div className="dropdown is-hoverable is-right">
                      <div
                        className="dropdown-trigger"
                        aria-haspopup="true"
                        aria-controls="login-dropdown"
                      >
                        <div className="button is-light is-fullwidth">
                          <span>
                            <Trans i18nKey="Global:phrase.login"></Trans>
                          </span>
                          <Icon name="chevron-down" />
                        </div>
                      </div>

                      <div className="dropdown-menu" id="login-dropdown" role="menu">
                        <div className="dropdown-content">
                          <a
                            href="https://merchants.stampit.co/"
                            className="st-header__dropdown-item has-icon"
                          >
                            <span>
                              <b>
                                <Trans i18nKey="Header:business"></Trans>
                              </b>{' '}
                              - <Trans i18nKey="Global:phrase.login"></Trans>
                            </span>
                            <Icon name="arrow-right" />
                          </a>

                          <hr className="dropdown-divider" />

                          <div className="st-header__dropdown-item">
                            <div>
                              <b>
                                <Trans i18nKey="Header:travelers"></Trans>
                              </b>{' '}
                              - <Trans i18nKey="Global:phrase.downloadApp"></Trans>
                            </div>

                            <div className="st-header__download-buttons">
                              <AppDownloadButton type="ios"></AppDownloadButton>
                              <AppDownloadButton type="android"></AppDownloadButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="control is-expanded">
                    <div className="dropdown is-hoverable is-right">
                      <div
                        className="dropdown-trigger"
                        aria-haspopup="true"
                        aria-controls="signup-dropdown"
                      >
                        <div className="button is-primary is-light is-fullwidth">
                          <span>
                            <Trans i18nKey="Global:phrase.signUp"></Trans>
                          </span>
                          <Icon name="chevron-down" />
                        </div>
                      </div>

                      <div className="dropdown-menu" id="signup-dropdown" role="menu">
                        <div className="dropdown-content">
                          <a
                            href="https://signup.stampit.co/"
                            className="st-header__dropdown-item has-icon"
                          >
                            <span>
                              <b>
                                <Trans i18nKey="Header:business"></Trans>
                              </b>{' '}
                              - <Trans i18nKey="Header:signUp"></Trans>
                            </span>
                            <Icon name="arrow-right" />
                          </a>

                          <hr className="dropdown-divider" />

                          <div className="st-header__dropdown-item">
                            <div>
                              <b>
                                <Trans i18nKey="Header:travelers"></Trans>
                              </b>{' '}
                              - <Trans i18nKey="Global:phrase.downloadApp"></Trans>
                            </div>

                            <div className="st-header__download-buttons">
                              <AppDownloadButton type="ios"></AppDownloadButton>
                              <AppDownloadButton type="android"></AppDownloadButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Sticky>
      </nav>
    );
  }
}

Header.propTypes = {
  menuLinks: PropTypes.array,
  hasDarkBackground: PropTypes.bool,
};

Header.defaultProps = {
  menuLinks: [],
  hasDarkBackground: false,
};

export default Header;
